<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4>Region Users Index</h4>
      <!-- Create Button Organization -->
      <router-link :to="{ name: 'TraineeRoleNew' }" tag="button" type="button" class="btn btn-success">Create Association for User To Region</router-link>
    </div>
    <div v-if="loaded">

      <!-- Region Users Index -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="rainbow-background"></div>
            <!-- Table Headers and Search Bar -->
            <ransack-filtering :headerWidth='20' :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters"></ransack-filtering>

            <div class="table-responsive">
              <table class="card-table table">
                <tbody class="text-left">
                <tr v-for="regionUser in regionUsers" :key="regionUser.id" :id="regionUser.id">
                  <td class="w-20">{{ regionUser.user_name }}</td>
                  <td class="w-20">{{ regionUser.user_role }}</td>
                  <td class="w-20">{{ regionUser.region_name }}</td>
                  <td class="w-20">{{ regionUser.default }}</td>
                  <td class="w-20">
                    <div class="btn-group float-right">
                      <router-link :to="{ name: 'RegionUserEdit', params: { id: regionUser.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                      <router-link :to="{ name: 'RegionUserShow', params: { id: regionUser.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange"></pagination>

          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <loading-overlay></loading-overlay>
    </div>

  </div>
</template>

<script>

import { RegionUserService } from '@/common/services/region_user.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'RegionUserIndex',
  components: {
    Pagination,
    RansackFiltering,
    LoadingOverlay,
  },
  data() {
    return {
      regionUsers: [],
      traineeRoles: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['User', 'Role', 'Region', 'Default?', ''],
      searchParam: 'user_id_cont',
      filters: FilterService.defaultData(),
      loaded: false,
    };
  },
  created() {
    this.getRegionUsers(this.currentPage, this.perPage);
  },
  methods: {
    getRegionUsers(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      RegionUserService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        if (response.data.length > 0) this.regionUsers = response.data;
        else this.regionUsers = [];
        this.loaded = true;
      });
    },
    onChange(page, perPage) {
      this.getRegionUsers(page, perPage);
    },
    setFilters(filters) {
      this.filters = filters;
      this.getRegionUsers(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  .w-20 {
    width: 19%;
  }
  .cursor {
    cursor: pointer;
  }
</style>
